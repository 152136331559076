@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --subheading: Inter;

  /* font sizes */
  --small-text-size: 16px;
  --subheading-size: 24px;
  --body-text-size: 20px;
  --heading-size: 40px;
  --font-size-sm: 14px;

  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-gray: #828282;
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro-200: #e0e0e0;
  --color-whitesmoke: #eee;

  /* Spacing */
  --spacing-sm: 32px;
  --spacing-m: 48px;

  /* Gaps */
  --gap-5xl: 24px;
  --gap-5xs: 8px;
  --gap-9xs: 4px;
  --gap-37xl: 56px;
  --gap-13xl: 32px;
  --gap-xs: 12px;
  --gap-sm: 14px;
  --gap-lgi: 19px;
  --gap-12xl: 31px;

  /* Paddings */
  --padding-3xs: 10px;
  --padding-base: 16px;
  --padding-xs: 12px;
  --padding-11xl: 30px;
  --padding-29xl: 48px;
  --padding-sm: 14px;
  --padding-5xl: 24px;
  --padding-8xl: 27px;
  --padding-6xs: 7px;
  --padding-22xl: 41px;
  --padding-4xs: 9px;
  --padding-7xs: 6px;
  --padding-5xs: 8px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-xs: 12px;

  /* Effects */
  --button-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}
